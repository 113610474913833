<template>
  <layout>
    <template slot="content">
      <div>
        <div class="page-header mb-3 mb-md-2">
          <h3 class="page-title"> Clientes </h3>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Clientes</a></li>
              <li class="breadcrumb-item active" aria-current="page">Todos los clientes</li>
            </ol>
          </nav>
        </div>
        <div class="row align-items-center">
          <div class="col-lg-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <div class="row justify-content-between align-items-center mb-4">
                  <div class="col-auto ml-auto">
                    <button class="btn btn-sm btn-gradient-info" @click.prevent="openModelCreate">
                      <i class="mdi mdi-plus"></i> Nuevo
                    </button>
                  </div>
                </div>
                <datatable :api="apiEndPoint" :fields="fields"></datatable>
              </div>
            </div>
          </div>
        </div>
        <client-create></client-create>
        <client-update v-if="clientSelected" :payload="clientSelected"></client-update>
      </div>
    </template>
  </layout>
</template>

<script>
import Layout from '@/components/BaseLayout'
import Datatable from '@/components/TableLayout'
import ClientCreate from '@/components/CreateClient'
import ClientUpdate from '@/components/EditClient'
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'Client',
  data () {
    return {
      apiEndPoint: 'api/clients',
      fields: [
        {
          name: 'type_id',
          title: 'Tipo',
          visible: false,
          formatter: (value) => {
            let status = '<i class="mdi mdi-account text-info" style="font-size:1.2rem"></i>'
            if (value === 2) {
              status = '<i class="mdi mdi-city text-info" style="font-size:1.2rem"></i>'
            }
            return status
          },
          sortField: 'type_id'
        },
        {
          name: 'full_name_or_company',
          title: 'Nombre',
          sortField: 'first_name'
        },
        {
          name: 'email',
          title: 'Correo',
          sortField: 'email'
        },
        {
          name: 'phone',
          title: 'Teléfono',
          ortField: 'phone'
        },
        {
          name: 'client_agenda_count',
          title: 'Tareas',
          titleClass: 'text-center',
          dataClass: 'text-center'
        },
        {
          name: 'client_records_count',
          title: 'Expedientes',
          titleClass: 'text-center',
          dataClass: 'text-center'
        }
      ],
      clientSelected: {}
    }
  },
  methods: {
    ...mapMutations('Client', ['TOGGLE_MODAL_CREATE', 'TOGGLE_MODAL_UPDATE']),
    ...mapActions('Client', ['delete', 'restore']),
    openModelCreate () {
      this.TOGGLE_MODAL_CREATE()
    },
    openModelUpdate () {
      this.TOGGLE_MODAL_UPDATE()
    },
    deleteClient () {
      this.delete(this.clientSelected)
        .then(() => {
          this.$events.fire('refresh-table')
        })
        .catch(error => {
          this.errors = error.response.data.errors
          if (!error.response.data.errors) {
            this.$swal(error.response.data.message, '', 'error')
          }
        })
    },
    restoreClient () {
      this.restore(this.clientSelected)
        .then(() => {
          this.$events.fire('refresh-table')
        })
        .catch(error => {
          this.errors = error.response.data.errors
          if (!error.response.data.errors) {
            this.$swal(error.response.data.message, '', 'error')
          }
        })
    }
  },
  mounted () {
    this.$events.$on('table-item-edit', eventData => {
      this.clientSelected = eventData
      this.openModelUpdate()
    })
    this.$events.$on('table-item-delete', eventData => {
      this.clientSelected = eventData
      this.$nextTick(() => { this.deleteClient() })
    })
    this.$events.$on('table-item-restore', eventData => {
      this.clientSelected = eventData
      this.$nextTick(() => { this.restoreClient() })
    })
    this.$events.$on('reset-client-selected', eventData => {
      this.clientSelected = {}
    })
  },
  beforeDestroy () {
    this.$events.off('table-item-edit')
    this.$events.off('table-item-delete')
    this.$events.off('table-item-restore')
    this.$events.off('reset-client-selected')
  },
  components: {
    Layout,
    Datatable,
    ClientCreate,
    ClientUpdate
  }
}
</script>
